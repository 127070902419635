import React from 'react';
import OvalVideoEmbed from './OvalVideoEmbed';
import { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { CircleCheckBig, ChevronDown, Globe, Users, Book, Eye, Video, Cloud, Wrench, Pyramid, Menu } from 'lucide-react';
import TextEffects from './TextEffects';

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] focus:text-[#6a9ad4] transition-colors focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const YouTubeEmbed = ({ videoId }) => {
  return (
    <div className="relative w-full pb-[56.25%] mb-8">
      {/* Aspect ratio container */}
      <div
        className="absolute inset-0 bg-gradient-to-r from-[#3b79be] to-[#6a9ad4] rounded-[20px]"
        style={{
          clipPath: 'polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%)',
          padding: '4px',
        }}
      >
        <div
          className="w-full h-full bg-[#3b79be] bg-opacity-20 relative overflow-hidden rounded-[16px]"
          style={{ clipPath: 'polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%)' }}
        >
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-[16px]"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] bg-opacity-30 backdrop-blur-lg rounded-lg p-6 shadow-lg flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
      tabIndex="0"
    >
      <div
        className="bg-[#27548a] rounded-full p-3 mb-4"
        role="img"
        aria-label={title}
      >
        {icon}
      </div>
      <h3 className="text-xl font-semibold my-4">{title}</h3>
      <div className="text-[#c5d8ed]">{description}</div>
    </motion.div>
  );
};

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <TextEffects
      text="NechepsO"
      effect="typewriter"
      className="font-outhill text-white"
    />
  </h1>
);

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length, isPaused]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
      <div
          role="region"
          aria-roledescription="carousel"
          aria-label="Image carousel"
          style={{ position: 'relative', width: '100%', maxWidth: '800px', height: '450px', margin: '0 auto' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: index === currentIndex ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
              borderRadius: '16px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
          />
        ))}
      </div>
  );
};


const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div
    className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}
  >
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

export default function EnhancedNechepsoHomePage() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  return (
    <div
      className="min-h-screen bg-gradient-to-b from-[#0f2137] via-[#3776c1] to-[#a3c1e4] text-white overflow-hidden"
      role="main"
    >
      {/* Background Stars Parallax */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden" aria-hidden="true">
        {[...Array(100)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute rounded-full bg-white parallax"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: Math.random() * 4 + 1,
              height: Math.random() * 4 + 1,
            }}
            data-speed={Math.random() * 0.5 + 0.2}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0.7],
            }}
            transition={{
              repeat: Infinity,
              duration: Math.random() * 3 + 2,
              ease: 'easeInOut',
            }}
          />
        ))}
      </div>

      {/* Navigation */}
      <nav
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`}
        aria-label="Primary Navigation"
      >
        <div
          className={`absolute inset-0 transition-opacity duration-300 ${
            scrollY > 50 ? 'opacity-90' : 'opacity-0'
          }`}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]" />
        </div>
        <div
          className={`absolute inset-0 transition-opacity duration-300 ${
            scrollY > 50 ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <div className="absolute inset-0 backdrop-blur-md" />
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button
            className="md:hidden focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-expanded={isMenuOpen}
            aria-label="Toggle Menu"
          >
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>

      {/* Main Content */}
      <main className="container mx-auto px-4 pt-40 pb-16" id="main-content">
        {/*Header Section 1 */}
        <div className="flex flex-col md:flex-row items-center mb-16">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <OvalVideoEmbed videoSrc="/videos/Logo_animation.mp4" />
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-4xl md:text-6xl text-right font-bold mb-6"
            >
              Ancient Insights
              <br /><br />
              Modern Interface
              <br /><br />
              Meet Nechepso
            </motion.h1>
          </div>
        </div>

        {/*Header Section 2 */}
        <div className="flex flex-col md:flex-row items-center mb-16">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-3xl md:text-2xl mb-8 text-left"
            >
              Since the dawn of the 21st century,
              <br />
              astrologers have wished on a star
              <br />
              for better chart calculation software.
              <br />
              Introducing <NechepsoText className="font-bold" />
              <br />
              <br />
            </motion.p>
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <YouTubeEmbed videoId="uX39FCCFroI" />
          </div>
        </div>

        {/*Header Section 3 */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="w-full md:w-2/3 lg:w-1/2 mb-8 md:mb-0 flex justify-center">
            <img
              src="/images/darkmode.png"
              alt="Dark Mode view of Nechepso"
              className="w-10/12 h-auto rounded-3xl shadow-lg"
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 md:pl-8">
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-2xl md:text-2xl mb-8 text-right"
            >
              As the next generation of
              <br />
              astrology software, <NechepsoText className="font-bold" /> <br />
              is a leap into the future
              <br />
              of astrological practice,
              <br />
              consulting, & research.
              <br /><br /><br />
            </motion.p>
          </div>
        </div>
        
        {/*Header Section 4 */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-xl md:text-2xl mb-8 text-left"
            >
              Made by astrologers for
              <br />
              astrologers, <NechepsoText className="font-bold" /> bridges
              <br />
              ancient wisdom with modern
              <br />
              innovation, empowering you
              <br />
              like never before.
              <br /><br />
              Become irreplaceable with
              <br />
              <NechepsoText className="font-bold" /> by your side.
              <br />
            </motion.p>
          </div>
          <div className="w-full md:w-2/3 lg:w-1/2 md:pl-8 flex justify-center">
            <img
              src="/images/lightmode.png"
              alt="Light Mode view of Nechepso"
              className="w-10/12 h-auto rounded-3xl shadow-lg"
            />
          </div>
        </div>
      </main>

      {/* Program Features Section 1 */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
      >
        <ChevronDown
          className="w-8 h-8 text-[#c5d8ed] animate-bounce"
          aria-hidden="true"
        />
      </motion.div>
      <section id="features" className="py-20 relative overflow-hidden">
        <div
          className="absolute inset-0 bg-gradient-to-br from-[#2a5688] to-[#3b79be] opacity-70 transform -skew-y-6"
          aria-hidden="true"
        ></div>
        <div className="container mx-auto px-4 relative z-10">
          <h2 className="text-4xl font-bold text-center mb-12">
            Unparalleled Features
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<CircleCheckBig className="w-12 h-12 text-white" />}
              title="Effortless Navigation"
              description={
                <>
                  Say goodbye to cluttered interfaces and confusing navigation.
                  With <NechepsoText className="font-bold" />, you won't feel
                  like you need a degree to complete basic tasks—everything is
                  clear, streamlined, and right where you expect it to be.
                  Designed for intuitive use, it's built to keep you focused on
                  what matters most.
                </>
              }
            />
            <FeatureCard
              icon={
                <img
                  src="/images/algorithm.png"
                  alt="Algorithm icon representing efficiency"
                  className="w-12 h-12"
                />
              }
              title="Advanced Workspace"
              description={
                <>
                  The most capable chart workspace ever designed, featuring
                  dynamic timeline integration, the largest asteroid catalog to
                  date, multi-faceted tools across all disciplines, and
                  groundbreaking techniques. Preloaded with Nick Dagan Best's
                  chart database, a quarter century in the making, directly
                  within <NechepsoText className="font-bold" />.
                </>
              }
            />
            <FeatureCard
              icon={<Users className="w-12 h-12 text-white" />}
              title="Tailored to You"
              description={
                <>
                  The first astrology software that does more than just make
                  charts. It adapts to your journey, offering insights and tools
                  when you need them most. By actively engaging with its users,{' '}
                  <NechepsoText className="font-bold" /> is tailored to you - to
                  help you track your own chart and journal events as life
                  happens.
                </>
              }
            />
            <FeatureCard
              icon={
                <img
                  src="/images/horoscope1.png"
                  alt="Horoscope representation"
                  className="w-12 h-12"
                />
              }
              title="Built by Astrologers, for Astrologers"
              description={
                <>
                  Whether you're working with modern astrology, exploring the
                  depths of Hellenistic, medieval, or Vedic traditions,{' '}
                  <NechepsoText className="font-bold" /> has you covered.
                  Leading the way as the first software designed to handle
                  twilight charts with ease. It's the ultimate all-in-one tool,
                  built to meet the needs of every astrologer.
                </>
              }
            />
            <FeatureCard
              icon={<Eye className="w-12 h-12 text-white" />}
              title="Visually Stunning"
              description={
                <>
                  A user interface that just looks awesome. Built to balance
                  both form and function, our clean design makes interpreting
                  astrological data a breeze. With newly designed and
                  standardized glyphs, we aim to set a new visual standard for
                  how charts are displayed. Designed at each turn to look
                  amazing, <NechepsoText className="font-bold" /> has a
                  beautiful frontend design that is unmatched. Welcome to the
                  21st Century.
                </>
              }
            />
            <FeatureCard
              icon={
                <img
                  src="/images/circles.png"
                  alt="Circles representing connections"
                  className="w-12 h-12 ml-1"
                />
              }
              title="Connections in the Stars"
              description={
                <>
                  Stay connected like never before with{' '}
                  <NechepsoText className="font-bold" /> using Circles, designed
                  to help you track and manage the people who matter most.
                  Whether it's friends, family, or key contacts, Circles keeps
                  you up to date with your closest connections. It's your
                  personal network, powered by the stars.
                </>
              }
            />
          </div>
        </div>
      </section>
      
      {/* Image Carousel */}
      <div className="container mx-auto px-4 mt-16">
        <div className="flex justify-center">
          <div className="w-full max-w-4xl">
            <ImageCarousel
              images={[
                '/images/PersonalModule.png',
                '/images/Nechepso_HomeScreen.png',
                '/images/ResearchModule.png',
                '/images/newchart.png',
              ]}
            />
          </div>
        </div>
      </div>
      {/* Program Features Section 2 */}
      <section id="cutting-edge" className="py-20 relative overflow-hidden">
        <div
          className="absolute inset-0 bg-gradient-to-tl from-[#3b79be] to-[#535f8d] opacity-70 transform skew-y-6"
          aria-hidden="true"
        ></div>
        <div className="container mx-auto px-4 relative z-10">
          <h2 className="text-4xl font-bold text-center mb-12">
            Cutting-Edge Technology
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={
                <img
                  src="/images/thirdhouselogo-tpbg_white.png"
                  alt="ThirdHouse Logo"
                  className="w-12 h-12 ml-1"
                />
              }
              title="Third House Social"
              description={
                <>
                  Share smarter, not harder. Boasting direct integration with
                  our homegrown social network, Third House,{' '}
                  <NechepsoText className="font-bold" /> allows you to easily
                  share any of your work with the world. Seamlessly post charts,
                  insights, and more with just a few clicks. Sharing reimagined
                  for the digital age.
                </>
              }
            />
            <FeatureCard
              icon={<Book className="w-12 h-12 text-white" />}
              title="Rich in Resources"
              description={
                <>
                  Cutting out the middleman means we bring the sources directly
                  to you. With an ever-growing library,{' '}
                  <NechepsoText className="font-bold" /> aims to provide you
                  with the ancient texts and translations you need internally,
                  for easy access. With our platform skip the hassle and have a
                  single streamlined, organized system, ready when you are.
                </>
              }
            />
            <FeatureCard
              icon={<Globe className="w-12 h-12 text-white" />}
              title="Universal Compatibility"
              description={
                <>
                  Tired of feeling left out?{' '}
                  <NechepsoText className="font-bold" /> is available for
                  Windows, Mac, and Linux. Whether you're a PC enthusiast, a Mac
                  loyalist, or a Linux devotee, we've got you covered. No matter
                  your setup, you'll always have access to the most powerful
                  astrology tools. Never feel left out again.
                </>
              }
            />
            <FeatureCard
              icon={
                <img
                  src="/images/atlas.png"
                  alt="Atlas representation for Meridian Codex"
                  className="w-12 h-12"
                />
              }
              title="Meridian Codex"
              description={
                <>
                  Introducing our open-source atlas, the Meridian Codex.
                  Boasting every location on the planet, it's directly linked to
                  IANA, the world’s most accurate timezone database. This
                  ensures the highest precision for your charts, giving you
                  unmatched accuracy down to the smallest detail.{' '}
                  <NechepsoText className="font-bold" /> leaves no room for
                  guesswork—only the most reliable results.
                </>
              }
            />
            <FeatureCard
              icon={<Video className="w-12 h-12 text-white" />}
              title="Video Integration"
              description={
                <>
                  Connect your way with multiple options for video
                  consultations. <NechepsoText className="font-bold" /> offers
                  integration with top services and an internal, encrypted
                  system that's free of charge—saving you from unnecessary fees.
                  Handle your sessions with confidence, from start to finish,
                  without missing a detail. It's everything you need for
                  seamless consultations, all in one platform.
                </>
              }
            />
            <FeatureCard
              icon={<Wrench className="w-12 h-12 text-white" />}
              title="Never-Before-Seen Tools"
              description={
                <>
                  Rewriting the rulebook. With entirely new ways of looking at
                  charts, aspects, and all the essential tools for your
                  practice, our software brings unmatched precision and
                  innovation to your fingertips. Welcome to a new era of
                  capabilities. Exclusively with{' '}
                  <NechepsoText className="font-bold" />.
                </>
              }
            />
          </div>
        </div>
      </section>
      {/* Purchase Your Subscription */}
      <section id="purchasesub" className="py-20 relative overflow-hidden">
        <div
          className="absolute inset-0 bg-gradient-to-br from-[#3b79be] to-[#2a5688] opacity-70 transform -skew-y-6"
          aria-hidden="true"
        ></div>
        <div className="container mx-auto px-4 text-center relative z-10">
          <h2 className="text-4xl font-bold mb-8">Changing the Industry Standard</h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">
            <NechepsoText className="font-bold" /> is at the forefront of
            astrological innovation. Experience the most visually appealing and
            powerful astrology software ever created.
          </p>
          <motion.a
            href="https://www.nechepso.com/pricing"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-12 py-6 rounded-full bg-gradient-to-r from-[#ff0000] to-[#990000] text-white font-semibold text-2xl hover:from-[#ff3333] hover:to-[#bb0000] transition-all duration-300 shadow-lg hover:shadow-xl inline-block"
          >
            Subscribe Today
          </motion.a>
        </div>
      </section>

      <footer className="bg-[#2a5688] py-8 relative">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p>©2024 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
            backgroundSize: '50px 100%',
            opacity: 0.2,
          }}
          aria-hidden="true"
        ></div>
      </footer>
    </div>
  );
}